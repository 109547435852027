<template>
    <div class="c-page-frame relative">
        <div class="c-page-frame__content u-scrollbar w-full p-8">
            <slot />
        </div>
        <div class="c-page-frame__background u-gradient-border h-full w-full"></div>
    </div>
</template>

<!-- 
    add style tag with lang scss.
    Stack Background and Screen with grid method.
 -->
<style lang="scss"></style>
